<template>
    <div class="card" style="width: 100%; border-radius: 0px; box-shadow: none; background: none;" >
        <div class="card-header border-0 pt-6">
            <div class="card-title" style="width:100%;">
            <h2>{{$t("Votre demande de réservation")}}</h2>
            </div>
        </div>
        <div class="card-body pt-0">
            <hr style="margin-top: -5px;">
            
            <Datatable
            v-if="form.myList && form.myList.length > 0"
            :table-data="form.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="false"
            :empty-table-text="$t('Aucune ligne à afficher.')"
            class="table-small-td"
            order ='dec'
            sortLabel="qte"
            :key="state.reload"
            >
            <template v-slot:cell-ref="{ row: data }">
                <span style="color:#000;" class="fs-6">{{ data.ref }}</span>
                <br>
                <span style="color:#959595;" class="fs-7">{{ data.desc }}</span>
            </template>
            <template v-slot:cell-qte="{ row: data }">
                <div style="width:100px; float: right;">
                    <el-popconfirm
                        width="220"
                        :confirm-button-text="$t('Oui, retirer cette référence')"
                        :cancel-button-text="$t('Non')"
                        icon-color="#ff0000"
                        :title="$t('Retirer de la liste ?')"
                        @confirm="deleteProd(data.ref)"
                    >
                        <template #reference>
                            <input type="button" style="border: 0px solid #c3c3c3; background: #ff000000;padding: 5px; top: 10px; float: right; " value="🗑️" />
                        </template>
                    </el-popconfirm>
                    <input type="number" @change="changeVal(data)" v-model="data.qte" class="w-70px" min="1" style="text-align:left; border: 1px solid #c3c3c3; padding: 5px; float : right" />
                </div>
            </template>
            </Datatable>

            <textarea
            class="form-control form-control-solid mb-6 mt-6"
            rows="3"
            :placeholder="$t('Commentaires sur votre demande')"
            v-model="form.commentaires"
            @change="changeCom(form.commentaires)"
            ></textarea>

            <div href="#" style="float: right; position: relative;" class="btn btn-sm btn-primary shadow"
                @click = "reservation()"
            >{{$t("Transmettre votre demande")}}
            </div>
        </div>
    </div>
</template>
  
  <script lang="ts">
  import { defineComponent, reactive, onMounted, ref } from "vue";
  import mAxiosApi from "@/api";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { useI18n } from "vue-i18n";
  
  export default defineComponent({
    name: "Panier",
    props: {
        addArticle: Object,    
    },
    emits: ["toggleAside"],
    components: {
      Datatable,
    },
    setup(props, { emit }) {
      
      const { t } = useI18n()
      const state = reactive({
        tmpMyList: [] as any,
        reload:1,
      });

      const form = reactive({
        myList: [] as any,
        commentaires : '' as any,
      });
  
      const tableHeader = ref([
      { name: "Référence produit", key: "ref", sortable: true,},
      { name: "Quantité", key: "qte", sortable: true,},
      ]);

      
      onMounted(async () => {  

        const myJson = window.localStorage.getItem('panierStockChecker');
        if (myJson) {
            state.tmpMyList = JSON.parse(myJson);
        }

        const myComm = window.localStorage.getItem('panierStockCheckerCom');
        if (myComm) form.commentaires = myComm;

        if (props.addArticle && props.addArticle.ref) {
           const myRef = props.addArticle.ref;
           if (props.addArticle){ 
             const found = state.tmpMyList.find(e => e.ref === myRef);
             if (found) {
                state.tmpMyList.find(e => e.ref === myRef).qte += 1;
             }else {
                state.tmpMyList.push({'ref' : props.addArticle.ref, 'qte': 1, 'id': props.addArticle.ref, 'desc': props.addArticle.desc});
             }
           }            
        }
        
        window.localStorage.setItem('panierStockChecker', JSON.stringify(state.tmpMyList));
        
        form.myList = state.tmpMyList;
        if (form.myList.length > 0 ) emit("toggleAside", 1);
        
      });

      const deleteProd = (ref) => {
        form.myList = form.myList.filter( row => row.ref !== ref );
        window.localStorage.setItem('panierStockChecker', JSON.stringify(form.myList));
        state.reload = state.reload + 1;
        if (form.myList.length == 0 ) emit("toggleAside", 0);
      };

      const changeVal = (data) => {
        const myRef = data.ref;
        const found = state.tmpMyList.find(e => e.ref === myRef);
        if (found) form.myList.find(e => e.ref === myRef).qte = data.qte;
        window.localStorage.setItem('panierStockChecker', JSON.stringify(form.myList));        
      };

      const changeCom = (Com) => {
        window.localStorage.setItem('panierStockCheckerCom', Com);        
      };

      const reservation = async () =>{

        await mAxiosApi.post("/setDemande", { 'demande': form.myList, 'commentaire': form.commentaires });
        Swal.fire({
              title: t('Demande transmise à nos services'), 
              text:
                t('Un email de confirmation vous sera transmis dans les 24 heures'),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t('OK'),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
        })

        form.myList = [];
        window.localStorage.setItem('panierStockChecker', "");
        window.localStorage.setItem('panierStockCheckerCom', "");
        emit("toggleAside", 0);
 
      };

      return {
        state,
        form,
        tableHeader,
        deleteProd,
        reservation,
        changeVal,
        changeCom
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>