
import { defineComponent, ref, onMounted, reactive } from "vue";
import store from "@/store";
import * as moment from "moment";
import mAxiosApi from "@/api";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Panier from "@/views/still/deploy/panier.vue";
import Detail from "@/views/still/deploy/details.vue";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
    Panier,
    Detail
  },
  setup() {

    const router = useRouter();

    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      search : "",
      results: [],
      lastSearch : "",
      cSearch:-1,
      modeSearch : "simple",
      myList: [] as any,
      initialMyList: [],
      loadingDatatable: 0,
      searchTitle : "",
      isAuth : store.getters.isUserAuthenticated,
      displayPanier : 0,
      addArticle: {} as any,
      affPanier : 0,
      statusAside : 0,
      refProduit: "",
      mProduct: {} as any,
    });

   
    const tableHeader = ref([
      { name: "Référence produit", key: "ref", sortable: true,},
      { name: "Stock Stillnetwork", key: "qte", sortable: true, className:"hide-small"},
      { name: "Délai usine", key: "leadtime", sortable: true, className:"hide-small"},
      { name: " ", key: "id", sortable: true, className:"hide-small"},
    ]);

    const tableFilters = ref([
      { name: "Constructeur", key: "prod_c_manu_name", label: "prod_c_manu_name", },
      { name: "Famille de produit", key: "prod_c_category1", label: "prod_c_category1", },
      { name: "Classe de produit", key: "prod_c_category2", label: "prod_c_category2", },
      { name: "Sous-classe de produit", key: "prod_c_category3", label: "prod_c_category3", },
    ]);


    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
    };
    const formatDateAgo = (d) => {
      return moment.default().diff( d, 'days')*-1 + " " + t("jours");

      //return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    
    /*
      watch(state, debounce(() => {
        searchAPi(state.search);
      }, 1000));
    */

    const toggleDisplayPanier = (data) => {
      state.displayPanier = 1;
      state.addArticle = data;
      state.affPanier = state.affPanier+1;
    };

    const searchAPi = async (search) => {
      if (search.length < 3) {
        return false;
      }
      state.loaderEnabled = true;
      state.lastSearch = search;
      state.cSearch = 1;
      search = search.replaceAll('/','{');
      const mProduits = await getAxios("/getProducts/" + search);

      if(!mProduits.results) mProduits.results = [];
      
      mProduits.results.sort((a, b) => {
        const qteA = a.qte;
        const qteB = b.qte;
        if (qteA > qteB) return -1;
        if (qteA < qteB) return 1;
        return 0;
      });
      

      state.results = mProduits.results;
      state.myList = mProduits.results;
      state.initialMyList = mProduits.results;
      state.cSearch = 0;
      state.loadingDatatable++;


      let strProd = '';
      mProduits.results.forEach(el => {
        strProd = strProd + '{"vendorPartNumber": "'+ el.ref +'"},';
      });

      changeModeSearch('complex');

      state.loaderEnabled = false;
      //console.log("str", strProd);

    };


    const openAside = (data) => {
      state.mProduct = data;
      state.refProduit = data.id;
      state.statusAside = 1;
    };


    onMounted(async () => {  
      //state.loaderEnabled = false;

      if( router.currentRoute.value.params.search) {
        state.search = router.currentRoute.value.params.search as any;
        searchAPi(state.search);
      }

    });


    const changeModeSearch = (mode) => {
      state.searchTitle = state.lastSearch;
      state.modeSearch = mode;
      state.cSearch= mode == 'simple' ? 0 : 2;
      if (!state.isAuth && mode == 'complex' ) affAddAccount();
      
    };

    const affAddAccount = () => {
      router.push({ name: "sign-up" });
    }


    return {
      state,
      changeModeSearch,
      tableHeader,
      tableFilters,
      formatDate,
      formatDateAgo,
      searchAPi,
      toggleDisplayPanier,
      openAside
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
