
  import { defineComponent, reactive, onMounted, ref } from "vue";
  import mAxiosApi from "@/api";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { useI18n } from "vue-i18n";
  
  export default defineComponent({
    name: "Panier",
    props: {
        addArticle: Object,    
    },
    emits: ["toggleAside"],
    components: {
      Datatable,
    },
    setup(props, { emit }) {
      
      const { t } = useI18n()
      const state = reactive({
        tmpMyList: [] as any,
        reload:1,
      });

      const form = reactive({
        myList: [] as any,
        commentaires : '' as any,
      });
  
      const tableHeader = ref([
      { name: "Référence produit", key: "ref", sortable: true,},
      { name: "Quantité", key: "qte", sortable: true,},
      ]);

      
      onMounted(async () => {  

        const myJson = window.localStorage.getItem('panierStockChecker');
        if (myJson) {
            state.tmpMyList = JSON.parse(myJson);
        }

        const myComm = window.localStorage.getItem('panierStockCheckerCom');
        if (myComm) form.commentaires = myComm;

        if (props.addArticle && props.addArticle.ref) {
           const myRef = props.addArticle.ref;
           if (props.addArticle){ 
             const found = state.tmpMyList.find(e => e.ref === myRef);
             if (found) {
                state.tmpMyList.find(e => e.ref === myRef).qte += 1;
             }else {
                state.tmpMyList.push({'ref' : props.addArticle.ref, 'qte': 1, 'id': props.addArticle.ref, 'desc': props.addArticle.desc});
             }
           }            
        }
        
        window.localStorage.setItem('panierStockChecker', JSON.stringify(state.tmpMyList));
        
        form.myList = state.tmpMyList;
        if (form.myList.length > 0 ) emit("toggleAside", 1);
        
      });

      const deleteProd = (ref) => {
        form.myList = form.myList.filter( row => row.ref !== ref );
        window.localStorage.setItem('panierStockChecker', JSON.stringify(form.myList));
        state.reload = state.reload + 1;
        if (form.myList.length == 0 ) emit("toggleAside", 0);
      };

      const changeVal = (data) => {
        const myRef = data.ref;
        const found = state.tmpMyList.find(e => e.ref === myRef);
        if (found) form.myList.find(e => e.ref === myRef).qte = data.qte;
        window.localStorage.setItem('panierStockChecker', JSON.stringify(form.myList));        
      };

      const changeCom = (Com) => {
        window.localStorage.setItem('panierStockCheckerCom', Com);        
      };

      const reservation = async () =>{

        await mAxiosApi.post("/setDemande", { 'demande': form.myList, 'commentaire': form.commentaires });
        Swal.fire({
              title: t('Demande transmise à nos services'), 
              text:
                t('Un email de confirmation vous sera transmis dans les 24 heures'),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t('OK'),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
        })

        form.myList = [];
        window.localStorage.setItem('panierStockChecker', "");
        window.localStorage.setItem('panierStockCheckerCom', "");
        emit("toggleAside", 0);
 
      };

      return {
        state,
        form,
        tableHeader,
        deleteProd,
        reservation,
        changeVal,
        changeCom
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  