<template>
    <div class="card" style="width: 100%; border-radius: 0px; box-shadow: none; background: none;" >
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">{{$t('Détail du produit')}}</h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="closeDrawer()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
        <div class="card-body pt-0" style="overflow-y: scroll;  height: calc(100vh - 70px);">
         
          <template v-if="state.loaderEnabled">
            <div class="lds-ring" style="left:50%; margin-top:100px; margin-left: -42px;"><img src="media/logos/loada.png"/><div></div><div></div><div></div><div></div></div>
          </template>
        
          <template v-if="!state.loaderEnabled">
            <template v-if="state.headProduct">
              <table style="width: 100%; " rowspan="2" class="mt-4">
                <tr>
                  <td class="head-product p-3">{{$t('Constructeur')}}</td>
                  <td class="text-dark fs-6">{{ state.headProduct.prod_c_manu_name }}</td>
                </tr>
                <tr  class="pb-2">
                  <td class="head-product p-3">{{$t('Référence')}}</td>
                  <td>                      
                    <span class="text-dark fs-6">{{ state.headProduct.id }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="head-product p-3">{{$t('Description')}}</td>
                  <td>                      
                    <span class="text-dark fs-6">{{ state.headProduct.desc }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="head-product p-3">{{$t('Catégorie')}}</td>
                  <td>
                    <span style="color:#959595;">{{ state.headProduct.prod_c_category1 }} / </span>
                    <span style="color:#959595;">{{ state.headProduct.prod_c_category2 }} / </span>
                    <span class="text-primary">{{ state.headProduct.prod_c_category3 }}</span>
                  </td>
                </tr>
                <tr v-if="state.headProduct.leadtime">
                  <td class="head-product p-3" >{{$t('Délai usine')}}</td>
                  <td>
                    <span class="fs-6 text-dark"><b>{{$t('Sous')}} {{ formatDateAgo(state.headProduct.leadtime) }}</b> <i>({{$t('Soit à partir du')}} {{ formatDate(state.headProduct.leadtime) }})</i></span>
              
                  </td>
                </tr>
                <tr>
                  <td class="head-product p-3">{{$t('Stock neuf disponible')}}</td>
                  <td style="display: flex;flex-direction: column;">
                    <div v-if="state.headProduct.qte > 0" class="text-dark fs-5" style="display: flex;margin-top: 5px;">
                      <div class="badge badge-light-success me-3 fs-4 text-dark" style="width: 60px;font-weight: 700;text-align: center;">{{ state.headProduct.qte }}</div>
                      <div class="badge badge-success me-3" style="min-width: 140px;margin-top: 4px;  margin-bottom: 7px;">{{$t('Livrable sous 48 heures')}}</div>
                    </div>
                    
                    <div v-else class="text-dark fs-5" style="display: flex;margin-top: 5px;">
                      <div style="width: 0px;font-weight: 700;padding-right: 8px; text-align: right;"></div>
                      <div class="badge badge-danger me-3" style="width: 193px;">{{$t('Aucun stock neuf')}}</div>
                    </div>
                    
                  </td>
                </tr>
                <tr v-if="state.refurb">
                  <td class="head-product p-3">{{$t('Stock reconditionné')}}</td>
                  <td style="display: flex;flex-direction: column;">                      
                    <div v-if="state.refurb.ref_qte_3d > 0" class="text-dark fs-5" style="display: flex;margin-top: 5px;">
                      <div class="badge badge-light-warning me-3 fs-4 text-dark" style="width: 60px;font-weight: 700;padding-right: 8px; text-align: center;">{{ state.refurb.ref_qte_3d }}</div>
                      <div class="badge badge-warning me-3" style="min-width: 140px;margin-top: 4px;  margin-bottom: 7px;">{{$t('Livrable sous 5 jours')}}</div>
                    </div>
                    <div v-if="state.refurb.ref_qte_7d > 0" class="text-dark fs-5" style="display: flex;margin-top: 5px;">
                      <div class="badge badge-light-warning me-3 fs-4 text-dark" style="width: 60px;font-weight: 700;padding-right: 8px; text-align: center;">{{ state.refurb.ref_qte_7d }}</div>
                      <div class="badge badge-warning me-3" style="min-width: 140px;margin-top: 4px;  margin-bottom: 7px;">{{$t('Livrable sous 9 jours')}}</div>
                    </div>
                    <div v-if="state.refurb.ref_qte_30d > 0" class="text-dark fs-5" style="display: flex;margin-top: 5px;">
                      <div class="badge badge-light-warning me-3 fs-4 text-dark" style="width: 60px;font-weight: 700;padding-right: 8px; text-align: center;">{{ state.refurb.ref_qte_30d }}</div>
                      <div class="badge badge-warning me-3" style="min-width: 140px;margin-top: 4px;  margin-bottom: 7px;">{{$t('Livrable sous 1 mois')}}</div>
                    </div>
                  </td>
                </tr>
                <tr v-if="state.lstDistrib && state.lstDistrib.length > 0">
                  <td class="head-product p-3">{{$t('Distributeurs')}}</td>
                  <td>
                    <Datatable
                      v-if="state.lstDistrib && state.lstDistrib.length > 0"
                      :table-data="state.lstDistrib"
                      :table-header="tableHeader"
                      :enable-items-per-page-dropdown="false"
                      :empty-table-text="$t('Aucun stock disponible chez les distributeurs.')"
                      class="table-very-small-td table-product"
                    >
                    <template v-slot:cell-act_n_seq="{ row: data }">
                        <img style="margin-left:10px;" :src="retImg(data.act_c_raison_sociale)"/>
                      </template>
                      <template v-slot:cell-act_c_raison_sociale="{ row: data }">
                        <span style="color:#000;" class="fs-6">{{ data.act_c_raison_sociale }}</span>
                      </template>
                      <template v-slot:cell-prod_n_quantite="{ row: data }">
                        <span style="color:#000; text-align: right;" class="fs-6">{{ data.prod_n_quantite }}</span>
                      </template>

                    </Datatable>              
                  </td>
                </tr>
              </table>

            </template>
            
            <el-select v-model="state.tabsSelect" class="m-1 resp-select-details" style="width: 100%;" :placeholder="$t('Select')" size="large" v-if="state.tabFinal.length">
              <el-option v-for="item in state.tabFinal" :key="item.headerName" :label="item.headerName"  :value="item.mIndex" />
            </el-select>
            
            <el-tabs tab-position="left" class="mt-5 resp-tabs-details" v-model="state.tabsSelect">
              <el-tab-pane v-for="item in state.tabFinal" :key="item.headerName" :label="item.headerName">

                <template v-if="item.contents[0].attributeName">
                  <table style="width: 100%;" rowspan="2" class="mt-0">
                    <tr v-for="content in item.contents" :key="content.attributeName">
                      <td class="head-product p-3">{{ $t(content.attributeName) }}</td>
                      <td>
                        <span  v-html="content.attributeValue"></span> 
                      </td>
                    </tr>
                  </table>
                </template>
                
                <template v-else >
                  <div style="height: 490px; margin-top: 8px;">
                   <img :src="item.contents[0].attributeValue" style="padding: 20px; margin-left: 50%; transform: translate(-50%, 0px); box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; max-height: 440px;"/>
                  </div>
                </template>

              </el-tab-pane>
            </el-tabs>

        </template>
    </div>
  </div>
</template>
  
  <script lang="ts">
  import { defineComponent, reactive, onMounted, ref, computed } from "vue";
  import mAxiosApi from "@/api";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import * as moment from "moment";
  import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";
  
  export default defineComponent({
    name: "Panier",
    props: {
        refProduit: String,
        mProduct : Object,
    },
    emits: ["closeDrawer"],
    components: {
      Datatable,
    },
    setup(props, { emit }) {
      //moment.default.locale("fr");
      const { t } = useI18n()
      const state = reactive({
        loaderEnabled : true,
        lstDistrib: [] as any,
        detailProduct: {} as any,
        descriptionProduct: {} as any,
        descCisco: {} as any,
        tabCisco: [] as any,
        tabFinal: [] as any,
        headProduct : {} as any,
        reload:1,
        search : String as any,
        tabsSelect: '0' as any,
        refurb: {} as any,
      });


      const tableHeader = ref([
      { name: "", key: "act_n_seq", sortable: true,},
      { name: "Distributeur", key: "act_c_raison_sociale", sortable: true,},
      { name: "Quantité", key: "prod_n_quantite", sortable: true,},
      ]);


      const retImg = (rSoc) => {
        if (rSoc == 'WESTCON COMSTOR') return "/media/stock/WESTCON.jpg";
        if (rSoc == 'INGRAM') return "/media/stock/INGRAM.jpg";
        if (rSoc == 'ALSO') return "/media/stock/ALSO.jpg";
        if (rSoc == 'TD SYNNEX') return "/media/stock/TD.jpg";
      };
      
      const nb_total_quantite = computed(() => {
        let cpt = 0;
        for(let i=0; i < state.lstDistrib.length; i++){ 
          cpt += parseInt(state.lstDistrib[i].prod_n_quantite);
        }
        return cpt;
      });

      const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
      };
      const formatDateAgo = (d) => {
      return moment.default().diff( d, 'days')*-1 + " " + t("jours");
      };
      
      onMounted(async () => {  

        const response = await mAxiosApi.post('/getAllProducts/ALL', {products: props.refProduit});

        state.lstDistrib = response.data.results;
        state.headProduct = props.mProduct;
        state.search = props.refProduit; 
        state.search = state.search.replaceAll('/','{');
        
        const response2 = await getAxios('/getProductDetails/' + state.search);
        state.detailProduct = response2.results;

        const response3 = await getAxios('/getRefurb/' + state.search);
        state.refurb = response3.results;

        if (state.detailProduct && state.detailProduct['image']){

          state.tabFinal.push({  headerName: 'Visuel du produit', contents: [
            {attributeName: '',attributeValue: state.detailProduct.image},
          ] as any  });


        }
        
        if (state.detailProduct && state.headProduct && state.detailProduct['ciscoFields'] && state.headProduct.prod_c_manu_name == 'CISCO'){
          state.descCisco = state.detailProduct['ciscoFields'];
          state.tabFinal.push({  headerName: t('Spécificités CISCO'), mIndex : 0, contents: [

            {attributeName: t('Sous Groupe CISCO'),attributeValue: state.descCisco.productSubGroup?state.descCisco.productSubGroup:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Nom de service'),attributeValue: state.descCisco.serviceProgramName?state.descCisco.serviceProgramName:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Catégorie'),attributeValue: state.descCisco.itemCatalogCategory?state.descCisco.itemCatalogCategory:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Indicateur de configuration'),attributeValue: state.descCisco.configurationIndicator?state.descCisco.configurationIndicator:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Entité interne'),attributeValue: state.descCisco.internalBusinessEntity?state.descCisco.internalBusinessEntity:'<i>'+t('inconnu')+'</i>'},

            {attributeName: t('Type'),attributeValue: state.descCisco.itemType?state.descCisco.itemType:'<i>'+t('inconnu')+'</i>'},


          ] as any  });

        }

        if(state.detailProduct && state.detailProduct['technicalSpecifications']){
            state.descriptionProduct = state.detailProduct['technicalSpecifications'];

            state.descriptionProduct.forEach(el => {
              if (!state.tabFinal.find(item => item.headerName === el.headerName)) {
                state.tabFinal.push({ headerName: el.headerName, mIndex : 0, contents: [] as any });
                state.tabFinal.find(item => item.headerName === el.headerName)?.contents.push({
                  attributeName: el.attributeName,
                  attributeValue: el.attributeValue
                });
              } else {
                if(el.attributeName !== "Information marketing") {
                  state.tabFinal.find(item => item.headerName === el.headerName)?.contents.push({
                  attributeName: el.attributeName,
                  attributeValue: el.attributeValue
                });
                }
              }
            });
        }

        if(state.tabFinal && state.tabFinal.length > 0 ){
          let i = 0;
          state.tabFinal.forEach(element => {
            element.mIndex = i.toString();
            i++;
          });
        }

        state.loaderEnabled = false;
        


        
      });

      const closeDrawer = () => {
        emit("closeDrawer", 0);
      }

      return {
        state,
        tableHeader,
        nb_total_quantite,
        closeDrawer,
        formatDate,
        formatDateAgo,
        retImg
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>